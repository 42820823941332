#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.page-light-bg {
    background: $light-200;
}

.fill-vertical-space {
    display: flex;
    flex: 1;
    flex-direction: column;
}

footer a img {
    max-height: 50px;
}
