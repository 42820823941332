.skills-overview {
  padding-left: 0px;
  max-width: 92%;
}

.skill-overview-body {
  padding-left: 20px;
  padding-right: 10px;
}

.job-heading {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.common-badge {
  padding: 3px;
  margin-right: 10px;
  margin-bottom: 0px;
  margin-top: 1px;
  font-weight: 500;
  display: inline;
}

.industry-badge {
  padding: 3px;
  margin-right: 10px;
  margin-bottom: 0px;
  margin-top: 1px;
  font-weight: 500;
  display: inline;
}

.similar-jobs-section {
  padding-left: 10px;
}

.skill-badge {
  padding: 3px;
  margin-right: 14px;
  margin-bottom: 0px;
  margin-top: 5px;
  font-weight: 500;
  display: inline;
}

.full-max-width {
  max-width: 100%;
}
