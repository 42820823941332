.skills-quiz-v2 {
  $max-sm-modal-width: 28rem !important;
  $min-sm-modal-height: 16rem !important;

  .page-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .form {
    width: 65%;
  }

  .pgn__form-group {
    width: 101%;
  }

  .text {
    width: 65%;
  }

  .pgn__form-label {
    font-weight: bold;
  }
  .form-auto-suggest {
    .form-control {
      color: $gray-500;
    }
  }

  .modal-small {
    max-width: $max-sm-modal-width;
    min-height: $min-sm-modal-height;
  }
}
