// CSS for the program sidebar

$baseline: 20px !default;
$gray: $gray-600 !default;
$blue-d1: #005e90;
$primary-dark: rgba(6, 86, 131, 100) !default;
$primary-dark1: mix($black, $blue, 20%) !default;
$divider-color: rgb(226, 231, 236) !default;
$gray-base: rgba(65, 65, 65, 100) !default;
$gray-light2: mix($white, $gray, 60%) !default;
$gray-light3: mix($white, $gray, 80%) !default;

.progress-heading {
  color: $blue-d1;
  text-align: center;
  margin-bottom: 0;
  font: {
    size: 1.1em;
    weight: 700;
  }
}

.program-sidebar {
  padding: $baseline*1.5 $baseline*0.5;

  .program-record {
    text-align: left;
    padding-bottom: 2em;
  }

  .motivating-section {
    margin-left: $baseline*0.75;
    font-size: 0.9375em;
    width: $baseline*15.5;

    @include media-breakpoint-up(sm) {
      width: auto;
    }

    .motivating-message {
      color: $gray-700;
    }
  }

  .divider-heading {
    font-family: $font-family-sans-serif;
    font-weight: bold;
    color: $primary-dark;
    font-size: 0.9375em;
    line-height: normal;
    padding-bottom: $baseline*0.25;
    border-bottom: $baseline*0.15 solid $divider-color;
    margin: $baseline*0.5 0 $baseline 0;
  }

  .sidebar-button-wrapper {
    text-align: right;
    .sidebar-button {
      font-size: 0.9375em;
      background: transparent;
      border: 1px solid;
      padding: 10px 16px;
    }
  }

  .program-credit-pathways {
    padding-bottom: 2em;
  }

  .pathway-wrapper {
    margin-left: $baseline*0.75;
    width: $baseline*15.5;
    border-bottom: 1px solid $gray-light3;

    @include media-breakpoint-up(sm) {
      width: auto;
    }

    .pathway-info {
      margin: 15px 0;
      font-size: 0.9375em;
      color: #414141;

      .pathway-heading {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 3px;
      }
    }
  }

  .pathway-wrapper:last-child {
    border-bottom: none;
  }

  @include media-breakpoint-up(md) {
    float: right;
    width: $baseline*15;
    padding-right: $baseline*1.5;
    position: relative;
  }

  @include media-breakpoint-up(lg) {
    width: $baseline*22.5;

    .program-progress {
      margin-left: $baseline*2.5;
    }
  }
}

.certificate-heading {
  margin-bottom: 10px;

  @include media-breakpoint-up(md) {
    margin-right: 30px;
  }

  @include media-breakpoint-up(lg) {
    margin-left: 10px;
    margin-right: 0;
  }
}

.program-cert-link {
  display: inline-block;

  &:active,
  &:focus,
  &:hover {
    .program-cert {
      border-color: $primary-dark1;
    }
  }
}

.program-cert {
  width: 100%;
  border: 1px solid $divider-color;

  @include media-breakpoint-up(md) {
    width: calc(100% - 30px);
  }

  @include media-breakpoint-up(lg) {
    width: 100%;
  }
}

.course-list-heading {
  font-family: $font-family-sans-serif;
  font-weight: bold;
  color: $primary-dark;
  font-size: 0.9375em;
  line-height: normal;
  padding-bottom: 5px;
  border-bottom: 3px solid $divider-color;
  margin: {
    top: 10px;
    bottom: 20px;
  }

  .status {
    margin-right: 7px;
  }
}


.certificate-list {
  margin: 0 0 0 10px;
  list-style: none;

  .certificate {
    display: flex;
    flex-direction: row;
    padding: 5px 0 10px;

    .image-link {
      flex: 0 0 100px;

      @include media-breakpoint-up(md) {
        flex: 0 0 120px;
      }
    }
  }

  .certificate-link {
    margin-left: 20px;

    color: $black;
    font: {
      size: 1.1em;
      weight: 600;
    }

    @include media-breakpoint-up(md) {
      font-size: 0.9em;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.1em;
    }

    &:active,
    &:focus,
    &:hover {
      .sample-cert {
        border-color: $primary-dark1;
      }
    }
  }

  .sample-cert {
    width: 120px;
    border: 3px solid $gray-light2;
    border-radius: 5px;

    @include media-breakpoint-up(md) {
      width: 100px;
    }

    @include media-breakpoint-up(lg) {
      width: 120px;
    }
  }
}
