$exec-ed-error-heading-font-size: $font-size-base * 3.34;


.executive-education-2u-error-heading {
    line-height: $headings-line-height;

    .executive-education-2u-error-heading-red {
        font-size: $exec-ed-error-heading-font-size;
        font-weight: $font-weight-bold;
        color: $brand-500;
    }

    .executive-education-2u-error-heading-black {
        font-size: $exec-ed-error-heading-font-size;
        font-weight: $font-weight-bold;
        color: $dark-700;
    }
}

.executive-education-2u-error-heading-details {
    align-items: center;
    font-family: $font-family-monospace;
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
    line-height: $line-height-lg;
    color: $gray-700;
}

.executive-education-2u-error-link-description {
    align-items: center;
    font-family: $font-family-monospace;
    font-size: $font-size-sm;
    font-weight: $font-weight-normal;
    line-height: $line-height-sm;
    color: $gray-500;
}

.executive-education-2u-error-link-box {
    border: 1px solid $gray-300;
    border-radius: $border-radius;
    height: 24px;
    padding-top: map-get($spacers, 1);
    padding-bottom: map-get($spacers, 1);
    padding-left: map-get($spacers, 1);
    padding-right: map-get($spacers, 1);
    font-size: $font-size-xs;
    font-weight: $font-weight-normal;
    line-height: $line-height-sm;
}
