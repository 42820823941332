.add-between-space{
    display: flex;
    justify-content: space-between;
}

.circle-color {
    font-size:30px;
    color:green
}

.courses > div:nth-of-type(even) {
    background-color: rgb(251, 250, 249);
}
.white-space-pre{
    white-space: pre
}
