.academy-card {

  .academy-card-image {
    max-width: 50%;
    position: absolute;
    bottom: 1em;
    right: 1em;
 }
}
.academy-tag {
  padding: 6px;
  margin-right: 7px;
  margin-top: 8px;
  font-size: .875rem;
  font-weight: normal;
}
.tag-clear-button {
  padding: 0px;
  text-decoration: underline !important;
}

.pathway-section {
  background: {
    image: url('../../../assets/images/academies/academy-pathwaya-section.png');
  }

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  margin-left: 0px;
  margin-right: 0px;

  .inner-container {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .eyebrow {
    font-size: 22px;
    color: white;
    font-family: 'Roboto Mono';
  }

  .pathway-title {
    color: white;
    opacity: 1;
    font-family: Inter;
    font-size: 60px;
    font-weight: 900;
    text-align: left;
    display: flex;
    line-height: 1;
  }

  .pathway-description {
    color: white;
  }

  .explore-btn {
    width: 300px;
    height: 77px;
    font-size: 22px;
    font-weight: 400;
    padding: 10px 16px 10px 16px;
  }
}

.academy-title {
  font-size: 55px;
  line-height: 60px;
  letter-spacing: -0.02em;
}
