.side-image {
  clip-path: polygon(19% 0, 100% 0, 78% 100%, 0 100%);
  margin-top: 20px;
  height: 585px;
  width: 450px;
  object-fit: cover;
  object-position: right top;
}
.pgn__form-control-set{
  flex-direction: row;
}
.pgn__form-control-set-inline{
  align-items: unset;
}
