.courses-in-program-wrapper {
  .alert.alert-warning {
    padding: 16px 16px 12px 16px;

    span.alert-icon {
      margin-right: 5px;
    }
  }
}

