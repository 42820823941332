.preview-expand-body {
  max-height: 160px;
  height: auto;
  overflow: hidden;
  position: relative;
  -webkit-transition: max-height .5s ease;
  transition: max-height .5s ease;

  &.expanded {
    max-height: unset;
    -webkit-transition: max-height 1s ease;
    transition: max-height 1s ease;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 24px;
    background-image: -webkit-gradient(linear,left top,left bottom,from(hsla(0,0%,100%,0)),to($white));
    background-image: linear-gradient(180deg,hsla(0,0%,100%,0),$white);
  }
}
