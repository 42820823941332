.search-result-card {
  a {
    color: $gray-700;
    text-decoration: none;

    &:hover,
    &:focus {
      color: $gray-700;
      text-decoration: none;

      .card {
        box-shadow: $box-shadow-lg;
      }
    }
  }

  .card {
    height: 400px;
    width: 312px;
    padding: 10px;
    box-shadow: $box-shadow;
    overflow: hidden;
  }

  .pgn__card-image-cap {
    width: 100%;
    height: 100%;
    height: 72px;
    object-fit: cover;
    border: none;
  }

  .partner-logo-wrapper {
    padding: 5px;
    position: absolute;
    top: 54px;
    left: 20px;
    background: $white;
    border-radius: 3px;
    box-shadow: $box-shadow-sm;

    .partner-logo {
      width: auto;
      max-height: 45px;
    }
  }

  .card-body {
    padding: 0.25rem;
    margin-top: 16px;
    margin-left: 9px;

    .partner {
      font-size: $font-size-sm;
    }

    .skill-badge {
      padding: 3px;
      margin-right: 2px;
      margin-bottom: 0px;
      margin-top: 1px;
      font-size: 11px;
      font-weight: normal;
      display: inline;
    }
  }

  .card-footer {
    font-size: 0.75rem;

    .badge-text {
      font-family: $font-family-monospace;
      font-size: 0.75rem;
      font-weight: 400;
    }
  }
}
