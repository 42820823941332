@import './Menu.scss';

$header-logo-height-desktop: 1.75rem;
$header-logo-height-mobile: 1.5rem;

.site-header-mobile,
.site-header-desktop {
  background: $white;
  position: relative;
  z-index: 1000;

  .dropdown-item a {
    text-decoration: none;
  }

  .vertical-divider {
    border-left: 1px solid $gray-200;
  }
}

.site-header-mobile {
  .nav-link {
    text-decoration: none;
    cursor: pointer;
    &.active {
      color: $component-active-color;
      background-color: $component-active-bg;
    }
  }
  .logo,
  .vertical-divider {
    height: $header-logo-height-mobile;
  }
  .icon-button {
    display: inline-flex;
    line-height: 3rem;
    background: transparent;
    vertical-align: middle;
    text-align: center;
    border: none;
    height: 3rem;
    width: 3rem;
    padding: .75rem;
    justify-content: center;
    align-items:center;
    &:hover, &:focus {
      background: rgba(0,0,0,.1);
    }
  }
}

.site-header-desktop {
  border-bottom: 1px solid $gray-200;
  background: $white;
  .nav-link {
    text-decoration: none;
  }
  .logo,
  .vertical-divider {
    height: $header-logo-height-desktop;
  }
  .main-nav {
    min-height: 68px; // height of the div with nav links; this forces header with no nav links to be consistent height

    .nav-link {
      padding: 1.125rem 1rem;
      text-decoration: none;
      font-weight: 500;
      letter-spacing: .01em;
    }
    .nav-link:hover,
    .nav-link:focus,
    .expanded .nav-link {
      background: $component-active-bg;
      color: $component-active-color;
    }
    .nav-link.active {
      border-bottom: 4px solid $component-active-bg;
    }
    .menu {
      position: static;
      .menu-content {
        border-top: solid 2px $component-active-bg;
        left: 0;
        right: 0;
        box-shadow: 0 1px 2px rgba(0,0,0,.25);
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
        padding: 1rem;
      }
    }
  }
}

.skip-nav-link:active, .skip-nav-link:focus {
  position: relative;
  z-index: 1010;
  padding: $input-btn-padding-y-sm $input-btn-padding-x-sm;
  font-size: $font-size-sm;
}
