.program-faq {
  .program-section-heading {
    color: $primary-900;
  }

  .collapsible {
    margin-bottom: 1.3rem;
    border-radius: .3rem !important;
  }

  .faq {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .collapsible-trigger {
    font-weight: 700;
    font-size: 1.25rem;
    color: $primary-900;
    justify-content: flex-start !important;
  }

  .collapsible-icon {
    margin-left: .5rem !important;
  }

  .collapsible-trigger:hover {
    background-color: $dark-100;
  }

  .collapsible-trigger[aria-expanded="true"] {
    background-color: $dark-100;
  }

  .collapsible-body {
    font-weight: 350;
  }
}
