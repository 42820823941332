.dashboard-course-card-wrapper {
  @extend .position-relative;

  &:last-child {
    @extend .border-0;
  }

  .dashboard-course-card {
    &.exec-ed-course-card {
      p > a[href]:not(.btn) {
        color: $light-200;
        text-decoration-color: $light-200;

        &:hover {
          color: $light-500;
        }
      }
      background-color: $dark-200;
    }

    .notifications {
      li:last-child {
        .notification {
          @extend .mb-0;
        }
      }

      .notification {
        background: $info-100;
      }
    }

    @include media-breakpoint-down(xs) {
      .btn-xs-block {
        width: 100%;
      }
    }
  }
}
