// +notes:
// ---------------------
// * Styling for the program details main view and sidebar view

// +local variables/utilities:
// ----------------------
$btn-color-primary: rgba(6, 86, 131, 100) !default;

.program-details-header {
    background-color: rgb(252, 252, 252);
    display: flex;
    color: black;
    font-family: 'Open Sans';
    font-weight: normal;
    flex-wrap: wrap;
    padding-top: 40px;
    padding-bottom: 35px;
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
      padding: {
        left: 10px;
        right: 30px;
      }
    }

    @media (min-width: map-get($container-max-widths, xl)) {
      padding: {
        left: 10px;
        right: 30px;
      }
    }

    .hd-1 {
      font-size: 1.5em;

      @include media-breakpoint-up(md) {
        font-size: 2.375em;
      }
    }

    .program-details-icon {
      margin-left:3px;
      margin-top: 10px;
      height: auto;

      svg {
        width: inherit;
      }
    }

    .micromasters {
      fill: #005585;
      width: 200px;

      @include media-breakpoint-up(md) {
        width: 250px;
      }
    }

    .xseries {
      fill: #424242;
      width: 150px;

      @include media-breakpoint-up(md) {
        width: 200px;
      }
    }

    .professional.certificate {
      fill: #9a1f60;
      width: 250px;

      @include media-breakpoint-up(md) {
        width: 300px;
      }
    }

    .meta-info {
      margin: 0;
      display: block;

      @include media-breakpoint-up(md) {
        width: 70%;
      }

      @include media-breakpoint-up(lg) {
        width: 75%;
      }

      .program-title {
        font-weight: normal;
        font-size: 2em;
      }
    }

    .authoring-organizations {
      text-align: center;
      display: flex;

      @include media-breakpoint-up(md) {
        display: block;
      }

      .heading {
        font-weight: bold;
        color: rgba(6, 86, 131, 100);
        font-size: 0.9375em;
        margin-top: auto;
        margin-bottom: auto;

        @include media-breakpoint-up(md) {
          margin-right: 10px;
        }
      }

      @include media-breakpoint-up(md) {
        margin: initial;
        width: 30%;

        .orgs .org-logo {
          margin-left: 2.5%;
          width: 46.5%;
          height: auto;
        }
      }

      @include media-breakpoint-up(lg) {
        width: 25%;
      }
    }
}
