.course-recommendations {
  margin-top: 2rem;

  .course-recommendations-image {
    width: inherit;
  }

  .course-recommendations-details {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}
