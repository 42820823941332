.remaining-courses{
  background: gray !important;
}

.completed-courses{
  background: $success-500 !important;
}

.in-progress-courses{
  background: #04262B !important;
}

