.callout-wrapper {
  .content {
    background: $dark-100;
    border: 2px solid $primary-200;
    border-radius: 4px;
    padding: 30px 48px;
    margin-bottom: 30px;
  }
}

.endorsements {
  margin: 0;
  padding-bottom: 30px;

  .quote-icon-wrapper {
    display: block;
    margin-left: -45px;
    color: $info-500;

    .quote-icon {
      position: absolute;
      transform: rotateY(180deg) rotateX(180deg);
      margin-top: -12px;
    }
  }

  .section-title {
    padding: 0 30px;

    @include media-breakpoint-up(sm) {
      padding: 0;
    }
  }

  .content {
    padding: {
      left: 30px;
      right: 30px;
    }

    font-size: 1.5rem;
  }

  .company-endorser-logo-wrapper {
    border: 1px solid $primary-200;
    border-radius: 50%;
    background-color: $white;
    width: 110px;
    height: 110px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 14px;

    .company-endorser-logo {
      width: 80px;
    }
  }
}
